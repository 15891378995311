
import { computed, defineComponent } from 'vue';
export default defineComponent({
  name: 'InputPhone',
  props: {
    modelValue: {
      type: String,
      default: undefined,
    },
  },
  inheritAttrs: false,
  emits: ['update:modelValue', 'blur', 'change'],
  setup(props, { emit }) {
    const values = computed(() => (props.modelValue && props.modelValue.split('-')) || ['', '']);
    const handleChangeValue1 = (e: Event) => {
      const newVal = `${(e.target as HTMLInputElement).value}-${values.value[1]}`;
      emit('update:modelValue', newVal);
      emit('change', newVal);
    };
    const handleChangeValue2 = (e: Event) => {
      const newVal = `${values.value[0]}-${(e.target as HTMLInputElement).value}`;
      emit('update:modelValue', newVal);
      emit('change', newVal);
    };

    return {
      values,
      handleChangeValue1,
      handleChangeValue2,
    };
  },
});
