
import { defineComponent, reactive } from 'vue';
import CitySelect from '@/components/city-select/index.vue';
import InputPhone from '@/components/input-phone/index.vue';
import { useForm } from 'ant-design-vue/es/form';
import { UploadOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  name: 'BaseSettings',
  setup() {
    const modelRef = reactive({
      email: 'antdesign@alipay.com',
      nickname: 'Serati Ma',
      bio: undefined,
      country: undefined,
      province: undefined,
      address: undefined,
      phoneNumber: '',
    });
    const rulesRef = reactive({
      email: [{ required: true, trigger: ['change', 'blur'] }],
      nickname: [{ required: true, trigger: ['change', 'blur'] }],
      address: [{ required: true, trigger: ['change', 'blur'] }],
      phoneNumber: [{ required: true, trigger: ['change', 'blur'] }],
    });
    const { validateInfos, validate } = useForm(modelRef, rulesRef);
    const handleSubmit = () => {
      validate()
        .then(res => {
          console.log('Update Information then:', res);
        })
        .catch(err => {
          console.error('Update Information err:', err);
        });
    };
    return {
      modelRef,
      validateInfos,
      handleSubmit,
    };
  },
  components: {
    CitySelect,
    InputPhone,
    UploadOutlined,
  },
});
