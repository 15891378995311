
import { toRaw, defineComponent, PropType, computed, reactive } from 'vue';
import { GeographicItemType } from './typing';
import { LabeledValue } from 'ant-design-vue/es/select';
import localProvince from './geographic/province.json';
import localCity from './geographic/city.json';

export default defineComponent({
  name: 'CitySelect',
  props: {
    province: {
      type: Array as PropType<GeographicItemType[]>,
      default: () => undefined,
    },
    city: {
      type: Array as PropType<GeographicItemType[]>,
      default: () => undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const state = reactive<{
      province?: LabeledValue;
      city?: LabeledValue;
    }>({
      province: undefined,
      city: undefined,
    });
    const loadedCity = computed(() => {
      return (state.province && localCity[state.province.key as string]) || [];
    });

    const selectProvinceItem = () => {
      state.city = undefined;
      emit('change', toRaw(state));
    };
    const selectCityItem = () => {
      emit('change', toRaw(state));
    };

    return {
      state,
      localProvince,
      loadedCity,

      selectProvinceItem,
      selectCityItem,
    };
  },
});
